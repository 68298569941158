// import me from '../src/images'

export const products = [
    {
        id: 1,
        img: "../src/images/1.jpg",
        link: "https://www.youtube.com/watch?v=NWG1Ygt1k1k",
    },
    {
        id: 2,
        img: "https://i.ibb.co/4TD6cYF/1.jpg",
        link: "http://lama.dev",
    },
    {
        id: 3,
        img: "https://i.ibb.co/4TD6cYF/1.jpg",
        link: "http://lama.dev",
    },
    {
        id: 4,
        img: "https://i.ibb.co/4TD6cYF/1.jpg",
        link: "http://lama.dev",
    },
    {
        id: 5,
        img: "https://i.ibb.co/4TD6cYF/1.jpg",
        link: "http://lama.dev",
    },
    {
        id: 6,
        img: "https://i.ibb.co/4TD6cYF/1.jpg",
        link: "http://lama.dev",
    },
]